﻿.bp-list--steps {
  font-family: $font-family-alt;
  text-transform: uppercase;

  .bp-list {
    &__item {
      align-items: center;
      display: flex;

      &:not(:first-child) {
        margin: 8px 0 0 0;
      }
    }

    &__number {
      align-items: center;
      // background-color: #000;
      background-color: var(--CF1);
      border-radius: 100%;
      color: #fff;
      display: flex;
      flex: 0 0 32px;
      font-size: 16px;
      justify-content: center;
      height: 32px;
      margin: 0;

      @include media-breakpoint-up(lg) {
        flex: 0 0 32px;
        font-size: 20px;
        height: 32px;
      }

      @include media-breakpoint-up(xl) {
        flex: 0 0 48px;
        font-size: 24px;
        height: 48px;
      }
    }

    &__content {
      margin: 0 0 0 8px;
    }

    &__title {
      font-size: 16px;
      line-height: normal;
      margin: 0;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 32px;
      }
    }

    &__description {
      font-size: 12px;
      line-height: normal;
      margin: 0;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }
  }
}

.bp-list--parkings {
  height: calc(100% - #{$header-minimal__height});
  overflow-y: auto;
  overflow-x: hidden;

  .bp-list {
    &__item {
      display: flex;
      justify-content: space-between;
      margin: 0 16px;
      padding: 4px 0;

      @include media-breakpoint-up(md) {
        padding: 16px 0;
      }

      &:not(:last-child) {
        border-bottom: 3px solid var(--bs-dark);
      }
    }

    &__content {
      margin: 0 16px 0 0;
    }

    &__title {
      font-family: $font-family-alt;
      font-size: 22px;
      line-height: 1;
      margin: 0 0 5px 0;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: 24px;
        margin: 0 0 16px 0;
      }
    }

    &__places {
      align-items: center;
      display: flex;
    }

    &__places-text {
      font-family: $font-family-alt;
      font-size: 12px;
      margin: 0 12px 0 0;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }

    &__places-number {
      align-items: center;
      border: 1px solid var(--CF3);
      border-radius: 100%;
      color: var(--CF6);
      display: flex;
      font-size: 14px;
      font-weight: 700;
      height: 24px;
      justify-content: center;
      margin: 0;
      width: 24px;
    }

    &__facilities {
      display: flex;
      flex-wrap: wrap;
      margin: 8px 0 0 0;

      img {
        height: 32px;
        width: 32px;
      }
    }

    &__prices {
      flex: 0 0 auto;
    }

    &__price {
      &:not(:last-child) {
        margin: 0 0 0 0;

        @include media-breakpoint-up(md) {
          margin: 0 0 4px 0;
        }
      }
    }

    &__price-title {
      font-family: $font-family-alt;
      font-size: 12px;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }

      span {
        font-size: 14px;
        text-transform: none;
      }
    }
  }

  .bp-list__button {
    font-family: $font-family-base;
    /* font-size: 12px;*/
    font-size: 16px;
    font-weight: 600;
    padding: 2px 16px;

    /*  @include media-breakpoint-up(md) {
      font-size: 16px;
    }
    */
    span {
      font-size: 12px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }
  }
}

.bp-list--parkings-selected {
  display: block;
  overflow: hidden;

  .bp-list__item {
    @include media-breakpoint-down(md) {
      background-color: #fff;
      bottom: 0;
      margin: 0;
      padding: 16px;
      position: absolute;
      width: 100%;
      z-index: 10;
    }
  }
}
