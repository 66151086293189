﻿/* Myriad Pro */

@font-face {
  font-family: "Myriad Pro";
  src: url("~/src/fonts/MyriadPro/MyriadPro-Regular.woff2") format("woff2"), url("~/src/fonts/MyriadPro/MyriadPro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("~/src/fonts/MyriadPro/MyriadPro-Bold.woff2") format("woff2"), url("~/src/fonts/MyriadPro/MyriadPro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Berlin Sans FB Demi */

@font-face {
  font-family: "Berlin Sans FB Demi";
  src: url("~/src/fonts/BerlinSansFBDemi-Bold/BerlinSansFBDemi-Bold.woff2") format("woff2"), url("~/src/fonts/BerlinSansFBDemi-Bold/BerlinSansFBDemi-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
