﻿/* Item Detail */

$item-detail__footer-height: 80px;

.bp-item--detail {
  background-color: #fff;
  height: calc(100vh - #{$header-minimal__height} - #{$item-detail__footer-height});
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 500;

  @include media-breakpoint-up(md) {
    height: calc(100% - #{$header-minimal__height} - #{$item-detail__footer-height});
    z-index: 1;
  }

  .bp-item {
    &__close {
      align-items: center;
      background: rgba(#fff, 0.6);
      color: var(--CF3);
      font-family: $font-family-alt;
      font-size: 18px;
      display: flex;
      height: 32px;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      transition: all ease 0.2s;
      width: 32px;

      &:hover {
        background-color: #fff;
      }
    }

    &__image {
      margin: 0 0 8px 0;
      position: relative;
      @include img-container;

      img {
        height: 180px;
        object-fit: cover;
        width: 100%;
      }
    }

    &__centered {
      @include centered;
      white-space: nowrap;
      color: white;
    }

    &__header {
      margin: 0 0 8px 0;
      padding: 0 16px;
    }

    &__body {
      padding: 0 16px;
    }

    &__heading {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &__title {
      color: var(--CF3);
      font-family: $font-family-alt;
      font-size: 32px;
      font-weight: 600;
      line-height: normal;
      margin: 0 8px 0 0;
      text-transform: uppercase;
    }

    &__info {
      align-items: center;
      font-size: 20px;
      color: var(--CF3);
      display: flex;

      span {
        align-items: center;
        border: 1px solid var(--CF3);
        border-radius: 100%;
        display: flex;
        font-size: 22px;
        height: 24px;
        justify-content: center;
        line-height: 24px;
        margin: 0 4px 0 0;
        width: 24px;
      }
    }

    &__address {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }

    &__places {
      align-items: center;
      color: var(--CF3);
      display: flex;
    }

    &__places-text {
      font-size: 16px;
      font-weight: 600;
      margin: 0 40px 0 0;
    }

    &__places-number {
      align-items: center;
      border: 1px solid var(--CF3);
      border-radius: 100%;
      display: flex;
      font-size: 20px;
      font-weight: 700;
      height: 32px;
      justify-content: center;
      margin: 0;
      width: 32px;
    }

    &__access {
      font-size: 16px;
      line-height: 1.3;
      margin: 8px 0 24px 0;
    }

    &__access-title {
      color: var(--CF3);
      font-weight: 600;
      margin: 0;
    }

    &__access-text {
      margin: 0;
    }

    &__schedule {
      font-size: 16px;
      font-weight: 600;
      margin: 0 0 24px 0;

      p {
        margin: 0;
      }
    }

    &__facilities {
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }

    &__facilities-item {
      align-items: center;
      display: flex;

      &:not(:last-child) {
        margin: 0 0 4px 0;
      }

      img {
        height: 24px;
        margin: 0 8px 0 0;
        width: 24px;
      }
    }

    &__footer {
      align-items: center;
      background-color: #fff;
      bottom: 48px;
      display: flex;
      gap: 8px;
      height: $item-detail__footer-height;
      left: 0;
      padding: 8px 16px;
      position: fixed;
      width: 100%;

      @include media-breakpoint-up(md) {
        bottom: 0;
      }
    }

    &__button {
      padding: 8px 16px;
    }

    &__button-inner {
      display: block;
      font-size: 18px;
      line-height: 1.2;

      span {
        font-size: 12px;
      }
    }
  }
}

/* Item Parking */
.bp-item--parking2 {
  padding: 32px 12px 80px 12px;

  @include media-breakpoint-up(lg) {
    padding: 64px 0 12px 0;
  }

  .bp-item {
    &__header {
      margin: 0 0 16px 0;

      @include media-breakpoint-up(lg) {
        margin: 0 0 32px 0;
      }

      @include media-breakpoint-up(xl) {
        margin: 0 0 64px 0;
      }
    }

    &__body {
      padding: 0 12px;

      @include media-breakpoint-up(lg) {
        padding: 0 64px;
      }
    }

    &__heading {
      margin: 0 0 16px 0;
    }

    &__title {
      color: var(--CF3);
      font-family: $font-family-alt;
      font-size: 32px;
      line-height: normal;
      margin: 0;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        font-size: 64px;
      }
    }

    &__address {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      color: var(--CF1);

      @include media-breakpoint-up(lg) {
        font-size: 20px;
      }
    }

    &__access {
      font-size: 16px;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }

      @include media-breakpoint-up(xl) {
        margin: 0 0 24px 0;
      }

      p {
        line-height: 1.3;
        margin: 0;
      }

      &-title {
        color: var(--CF3);
        font-weight: 600;
      }

      &-description {
        color: var(--CF4);
      }
    }

    &__schedule {
      color: var(--CF1);
      font-size: 16px;
      font-weight: 600;
      margin: 0 0 16px 0;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
        margin: 0 0 32px 0;
      }

      p {
        line-height: 1.1;
        margin: 0;
      }
    }

    &__buttons {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include media-breakpoint-up(xl) {
        flex-direction: row;
      }
    }

    &__button {
      padding: 8px 16px;
    }

    &__button-inner {
      display: block;
      font-size: 14px;
      line-height: 1.2;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }

      span {
        font-size: 10px;

        @include media-breakpoint-up(lg) {
          font-size: 12px;
        }
      }
    }

    &__facilities {
      margin: 0 0 16px 0;

      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: center;
        margin: 0 0 32px 0;
      }

      @include media-breakpoint-up(xl) {
        margin: 0 0 64px 0;
      }

      &-item {
        align-items: center;
        display: flex;

        @include media-breakpoint-up(lg) {
          display: block;
          flex: 0 0 108px;
        }

        @include media-breakpoint-up(xxl) {
          flex: 0 0 124px;
        }

        &:not(:last-child) {
          margin: 0 0 8px 0;

          @include media-breakpoint-up(lg) {
            margin: 0 24px 0 0;
          }

          @include media-breakpoint-up(xxl) {
            margin: 0 64px 0 0;
          }
        }
      }

      &-image {
        margin: 0 8px 0 0;

        @include media-breakpoint-up(lg) {
          margin: 0 0 8px 0;
        }

        img {
          height: 32px;
          width: 32px;
          margin: auto;
          display: block;

          @include media-breakpoint-up(lg) {
            height: 64px;
            width: 64px;
          }

          @include media-breakpoint-up(xxl) {
            height: 80px;
            width: 80px;
          }
        }
      }

      &-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        margin: 0;
        text-transform: uppercase;
        color: var(--CF1);

        @include media-breakpoint-up(xl) {
          font-size: 18px;
        }
      }
    }

    &__gallery {
      margin: 0 -24px 16px -24px;

      @include media-breakpoint-up(lg) {
        margin: 0 0 32px 0;
      }

      @include media-breakpoint-up(xl) {
        margin: 0 0 64px 0;
      }

      .slick-slide {
        margin: 0 8px;

        @include media-breakpoint-up(lg) {
          margin: 0 12px;
        }
      }

      img {
        max-height: 164px;

        @include media-breakpoint-up(lg) {
          max-height: 300px;
        }
      }
    }

    &__info {
      position: relative;

      &:before,
      &:after {
        background-repeat: no-repeat;
        content: "";
        height: 164px;
        position: absolute;
        width: 39px;
      }

      &:before {

        bottom: -64px;
        left: -48px;
        background-color: var(--FF2);
        clip-path: polygon(0% 1%,
            1% 0%,
            100% 12%,
            100% 88%,
            1% 100%,
            1% 99%);

        @include media-breakpoint-up(lg) {
          bottom: initial;
          left: -80px;
          top: 88px;
        }
      }

      &:after {

        right: -48px;
        top: 0;
        background-color: var(--FF1);
        clip-path: polygon(0% 12%,
            99% 0%,
            100% 1%,
            100% 99%,
            99% 100%,
            0% 88%);

        @include media-breakpoint-up(lg) {
          right: -80px;
          top: 88px;
        }
      }

      @include media-breakpoint-up(xl) {
        display: flex;
      }

      &--item {
        @include media-breakpoint-up(xl) {
          flex: 0 0 50%;
        }

        &:not(:last-child) {
          margin: 0 0 16px 0;

          @include media-breakpoint-up(xl) {
            margin: 0 32px 0 0;
          }
        }
      }

      &--title {
        color: var(--CF3);
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 16px 0;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          font-size: 24px;
        }
      }

      &--description {
        font-size: 16px;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
        }

        @include media-breakpoint-up(xl) {
          display: flex;
          gap: 16px;
        }

        p {
          line-height: 1.3;
          margin: 0 0 16px 0;

          @include media-breakpoint-up(xl) {
            margin: 0;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}

.bp-item--parking {
  padding: 32px 0 80px 0;

  @include media-breakpoint-up(lg) {
    padding: 64px 0 128px 0;
  }

  .bp-item {
    &__header {
      margin: 0 0 16px 0;

      @include media-breakpoint-up(lg) {
        margin: 0 0 32px 0;
      }

      @include media-breakpoint-up(xl) {
        margin: 0 0 64px 0;
      }
    }

    &__body {
      padding: 0 12px;

      @include media-breakpoint-up(lg) {
        padding: 0 64px;
      }
    }

    &__heading {
      margin: 0 0 16px 0;
    }

    &__title {
      color: var(--CF3);
      font-family: $font-family-alt;
      font-size: 32px;
      line-height: normal;
      margin: 0;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        font-size: 64px;
      }
    }

    &__address {
      font-size: 18px;
      font-weight: 600;
      margin: 0;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
      }
    }

    &__access {
      font-size: 16px;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }

      @include media-breakpoint-up(xl) {
        margin: 0 0 24px 0;
      }

      p {
        line-height: 1.3;
        margin: 0;
      }

      &-title {
        color: var(--CF3);
        font-weight: 600;
      }
    }

    &__schedule {
      font-size: 16px;
      font-weight: 600;
      margin: 0 0 16px 0;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
        margin: 0 0 32px 0;
      }

      p {
        line-height: 1.1;
        margin: 0;
      }
    }

    &__buttons {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include media-breakpoint-up(xl) {
        flex-direction: row;
      }
    }

    &__button {
      padding: 8px 16px;
    }

    &__button-inner {
      display: block;
      font-size: 14px;
      line-height: 1.2;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }

      span {
        font-size: 10px;

        @include media-breakpoint-up(lg) {
          font-size: 12px;
        }
      }
    }

    &__facilities {
      margin: 0 0 16px 0;

      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: center;
        margin: 0 0 32px 0;
      }

      @include media-breakpoint-up(xl) {
        margin: 0 0 64px 0;
      }

      &-item {
        align-items: center;
        display: flex;

        @include media-breakpoint-up(lg) {
          display: block;
          flex: 0 0 108px;
        }

        @include media-breakpoint-up(xxl) {
          flex: 0 0 124px;
        }

        &:not(:last-child) {
          margin: 0 0 8px 0;

          @include media-breakpoint-up(lg) {
            margin: 0 24px 0 0;
          }

          @include media-breakpoint-up(xxl) {
            margin: 0 64px 0 0;
          }
        }
      }

      &-image {
        margin: 0 8px 0 0;

        @include media-breakpoint-up(lg) {
          margin: 0 0 8px 0;
        }

        img {
          height: 32px;
          width: 32px;
          margin: auto;
          display: block;

          @include media-breakpoint-up(lg) {
            height: 64px;
            width: 64px;
          }

          @include media-breakpoint-up(xxl) {
            height: 80px;
            width: 80px;
          }
        }
      }

      &-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        margin: 0;
        text-transform: uppercase;

        @include media-breakpoint-up(xl) {
          font-size: 18px;
        }
      }
    }

    &__gallery {
      margin: 0 -24px 16px -24px;

      @include media-breakpoint-up(lg) {
        margin: 0 0 32px 0;
      }

      @include media-breakpoint-up(xl) {
        margin: 0 0 64px 0;
      }

      .slick-slide {
        margin: 0 8px;

        @include media-breakpoint-up(lg) {
          margin: 0 12px;
        }
      }

      img {
        max-height: 164px;

        @include media-breakpoint-up(lg) {
          max-height: 300px;
        }
      }
    }

    &__info {
      position: relative;

      &:before,
      &:after {
        background-repeat: no-repeat;
        content: "";
        height: 164px;
        position: absolute;
        width: 39px;
      }

      &:before {

        bottom: -64px;
        left: -48px;
        background-color: var(--FF2);
        clip-path: polygon(0% 1%,
            1% 0%,
            100% 12%,
            100% 88%,
            1% 100%,
            1% 99%);

        @include media-breakpoint-up(lg) {
          bottom: initial;
          left: -80px;
          top: 88px;
        }
      }

      &:after {

        right: -48px;
        top: 0;
        background-color: var(--FF1);
        clip-path: polygon(0% 12%,
            99% 0%,
            100% 1%,
            100% 99%,
            99% 100%,
            0% 88%);

        @include media-breakpoint-up(lg) {
          right: -80px;
          top: 88px;
        }
      }

      @include media-breakpoint-up(xl) {
        display: flex;
      }

      &--item {
        @include media-breakpoint-up(xl) {
          flex: 0 0 50%;
        }

        &:not(:last-child) {
          margin: 0 0 16px 0;

          @include media-breakpoint-up(xl) {
            margin: 0 32px 0 0;
          }
        }
      }

      &--title {
        color: var(--CF3);
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 16px 0;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          font-size: 24px;
        }
      }

      &--description {
        font-size: 16px;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
        }

        @include media-breakpoint-up(xl) {
          display: flex;
          gap: 16px;
        }

        p {
          line-height: 1.3;
          margin: 0 0 16px 0;

          @include media-breakpoint-up(xl) {
            margin: 0;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}

.bp-item__image {
  .bp-item {
    &__centered {
      @include centered;
      white-space: nowrap;
      color: white;
    }
  }
}

.bp-list--parkings {
  .bp-item {
    &__button {
      padding: 8px 16px;
    }

    &__button-inner {
      display: block;
      font-size: 16px;
      line-height: 1.2;

      span {
        font-size: 12px;
      }
    }


  }

}
