﻿.bp-text--default {
  .bp-text {
    &__title {
      font-family: $font-family-alt;
      font-size: 20px;
      margin: 0 0 16px 0;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: 22px;
        margin: 0 0 24px 0;
      }
    }

    &__description {
      font-size: 18px;

      @include media-breakpoint-up(md) {
        font-size: 20px;
      }

      p {
        margin: 0;

        &:not(:last-child) {
          margin: 0 0 16px 0;

          @include media-breakpoint-up(md) {
            margin: 0 0 24px 0;
          }
        }
      }
    }
  }
}

/***************** begin fonts sizes *****************/

.f1 {
  font-size: 26px !important;
}

@media (min-width: 768px) {
  .f1 {
    font-size: 32px !important;
  }
}

.f2 {
  font-size: 22px !important;
}

@media (min-width: 768px) {
  .f2 {
    font-size: 24px !important;
  }
}

.f3 {
  font-size: 18px !important;
}

@media (min-width: 768px) {
  .f3 {
    font-size: 20px !important;
  }
}

.f4 {
  font-size: 16px !important;
}

.f5 {
  font-size: 14px !important;
}

.f6 {
  font-size: 12px !important;
}

.f7 {
  font-size: 10px !important;
}

.f00-light {
  font-size: 40px !important;
  font-weight: 300 !important;
}
