﻿/* Bar View Mode */

.bp-bar--view-mode {
  align-items: center;
  background-color: var(--FF7);
  bottom: 0;
  display: flex;
  height: 48px;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 500;

  @include media-breakpoint-up(md) {
    display: none;
  }

  i {
    fill: #fff;
    height: 16px;
    margin: 0 0 0 8px;
    width: 16px;
  }

  .bp-bar {
    &__button {
      align-items: center;
      background-color: var(--FF1);
      border-radius: 4px;
      color: #fff;
      display: flex;
      font-size: 18px;
      padding: 4px 16px;

      &--hidden {
        display: none;
      }
    }
  }
}

body {
  background-color: var(--FA1);

  @include media-breakpoint-down(md) {
    &[data-viewmode='view-list'] {
      .bp-section__map {
        display: none;
      }

      .bp-list--parkings {
        display: block;
      }
    }

    &[data-viewmode='view-map'] {
      .bp-section__map {
        display: block;
      }

      .bp-list--parkings {
        display: none;
      }
    }
  }
}
