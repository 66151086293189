﻿:root {

  --bp-green: #aec342;

  --bp-blue-light: #97d1d1;
  --bp-blue-lightv2: rgb(197, 229, 229);
  --bs-dark: #000;


}
