﻿@import "bootstrap-override";

@import "bootstrap";
@import "fontawesome";

//@import "magnific-popup";
//@import "slick-carousel";
.form-check-input {
  &:checked {
    background-color: var(--FF1);
    border-color: var(--FF1);
  }

  //mix(white, $color, $weight)
  &:focus {
    border-color: color-mix(in srgb, white 50%, var(--FF1));
    outline: 0;
    box-shadow: 0 0 $focus-ring-blur $focus-ring-width var(--FF1);
    opacity: 0.25;
  }

}
