﻿.bp-section {
  &--default {
    padding: 40px 0;

    @include media-breakpoint-up(md) {
      padding: 64px 0;
    }
  }

  &--no-overflow {
    overflow: hidden;
  }
}

.bp-section--parking {
  overflow: hidden;
}

.bp-section--opinions {
  background-color: var(--bp-blue-lightv2);
  // margin: 48px 0 0 0;

  @include media-breakpoint-up(md) {
    padding-top: 112px;
  }
}

.bp-section--somos {
  margin-top: 12px;
}

.bp-section--main {
  padding: 78px 0 40px 0;

  @include media-breakpoint-up(md) {
    padding: 124px 0 40px 0;
  }
}

.bp-section--parking {
  padding-bottom: 0;

  .bp-section__container {
    background-color: var(--FA2);

    @include media-breakpoint-down(xxl) {
      max-width: none;
    }
  }
}

.bp-section--map {
  .bp-section {
    &__sidebar {
      background-color: #fff;
      bottom: 0;
      height: 100%;
      left: 0;
      padding-bottom: 48px;
      position: absolute;
      width: 100%;

      @include media-breakpoint-up(md) {
        padding-bottom: 0;
        width: 400px;
      }
    }

    &__map {
      bottom: 0;
      height: calc(100% - 64px);
      overflow: hidden;
      padding-bottom: 48px;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 5;

      @include media-breakpoint-up(md) {
        height: 100%;
        padding-bottom: 0;
        top: 0;
        width: calc(100% - 400px);
      }

      iframe {
        height: 100%;
        width: 100%;
      }
    }

    &__header {
      align-items: center;
      display: flex;
      height: 64px;
      /* justify-content: center; */
      padding: 0 16px;

      @include media-breakpoint-up(md) {
        border-bottom: 3px solid var(--bs-dark);
      }
    }

    &__ico {
      height: 48px;
      margin: 0 12px 0 0;
    }

    &__logo {
      filter: brightness(0%);
      height: 32px;
      transition: all ease 0.2s;

      &:hover {
        filter: none;
      }
    }
  }
}
