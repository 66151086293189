﻿.bp-header {
  left: 0;
  position: fixed;
  right: 0;
  transition: background-color ease 0.2s;
  width: 100%;
  z-index: 5;

  &__ico {
    height: 64px;
    position: absolute;
    top: 12px;
    z-index: 10000;

    @include media-breakpoint-up(md) {
      height: 108px;
    }
  }

  &__logo {
    filter: brightness(0%);
    height: 38px;
    margin: 0 0 0 56px;
    transition: all ease 0.2s;

    @include media-breakpoint-up(md) {
      height: 48px;
      margin: 0 0 0 108px;
    }

    &:hover {
      filter: none;
    }
  }
}

.bp-body--scrolled {
  .bp-header {
    background-color: #fff;
  }
}
