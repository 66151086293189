﻿.bp-heading {
  &--main {
    display: flex;
    font-family: $font-family-alt;
    justify-content: center;
    margin: 0 0 32px 0;

    @include media-breakpoint-up(md) {
      margin: 0 0 64px 0;
    }

    .bp-heading {
      &__number {
        border-right: 2px solid var(--FF1);
        color: var(--FF1);
        font-size: 24px;
        line-height: normal;
        margin: 0 16px 0 0;
        padding: 0 16px 0 0;

        @include media-breakpoint-up(md) {
          font-size: 32px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 40px;
          margin: 0 24px 0 0;
          padding: 0 24px 0 0;
        }
      }

      &__title {
        font-size: 32px;
        line-height: normal;
        margin: 0;
        text-transform: uppercase;
        color: var(--CF1);

        @include media-breakpoint-up(md) {
          font-size: 40px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 64px;
        }
      }
    }
  }

  &--image {
    margin: 0 0 32px 0;

    @include media-breakpoint-up(md) {
      margin: 0 0 64px 0;
    }
  }

  &--blue {
    .bp-heading {
      &__title {

        color: var(--CF3);
      }
    }
  }
}

.header-biciparc {
  color: $white;
  background-color: var(--FF9);
  border-color: var(--FF9);
}

.btn-location {
  position: relative;
  top: 0.5rem;
  right: 0;
}
