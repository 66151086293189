@mixin big-blue-text {
  font-family: Arial;
  font-size: 25px;
  font-weight: bold;
  color: #0000ff;
}

@mixin img-container {
  text-align: center;
  color: #fff;
  font-size: 32px;
  letter-spacing: 2px;
}
@mixin centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: $font-family-alt;
}
@mixin centeredv2 {
  /*position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
  font-family: $font-family-alt;
  display: flex; // make us of Flexbox
  align-items: center; // does vertically center the desired content
  justify-content: center; // horizontally centers single line items
  text-align: center;
}
