﻿.bp-features {
  &--ico {
    .bp-features {
      &__grid {
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
          justify-content: center;
        }
      }

      &__item {
        flex: 0 0 50%;

        @include media-breakpoint-up(md) {
          flex: 0 0 33.33%;
        }

        @include media-breakpoint-up(lg) {
          flex: 0 1 auto;
        }

        &:not(:last-child) {
          margin: 0 0 16px 0;

          @include media-breakpoint-up(lg) {
            margin: 0 32px 32px 0;
          }
        }

        img {
          height: 56px;
          width: auto;

          @media only screen and (min-width: $bp--iphone6) {
            height: 64px;
          }

          @include media-breakpoint-up(md) {
            height: 80px;
          }
        }
      }
    }
  }
}
