﻿.bp-card {
  &--places {
    @include media-breakpoint-down(sm) {
      margin: 0 -24px;
    }

    @include media-breakpoint-up(xxl) {
      padding: 0 64px;
    }

    .bp-card {
      &__grid {
        @include media-breakpoint-up(md) {
          display: grid;
          grid-gap: 24px;
          grid-template-columns: repeat(1, 360px 360px);
          justify-content: center;
        }

        @include media-breakpoint-up(xl) {
          grid-template-columns: repeat(1, 360px 360px 360px);
        }

        @include media-breakpoint-up(xxl) {
          grid-gap: 64px;
        }
      }

      &__item {
        background-color: transparent;
        height: 245px;
        margin: 0 auto;
        perspective: 1000px;
        width: 320px;
        cursor: pointer;

        @media only screen and (min-width: $bp--iphone6) {
          height: 100px;
          width: 375px;
          margin-bottom: 24px;
        }

        @include media-breakpoint-up(sm) {
          height: 278px;
          width: 360px;
        }

        @include media-breakpoint-up(md) {
          margin: 0;
        }

        &:not(:last-child) {
          margin-bottom: 24px;

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }

        &:first-child {
          position: relative;

          &:before {
            @include media-breakpoint-up(xxl) {

              //background: url("~/src/img/detail-pink.svg") no-repeat left top;
              background-color: var(--FF1);


              mask-image: url("~/src/img/detail-pink.svg");

              content: "";
              height: 243px;
              left: -164px;
              position: absolute;
              top: 0;
              width: 117px;


            }

          }
        }

        &:hover {
          .bp-card__inner {
            transform: rotateY(180deg);
          }
        }

        &:nth-child(even) {
          .bp-card__back:before {
            left: 0;
            right: -16px;
            transform: rotate(6deg);
          }
        }
      }

      &__image {
        height: 100px;
        object-fit: none;
        width: 375px;

        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        top: 0;

        z-index: 0;

        /*   @media only screen and (min-width: $bp--iphone6) {
          height: 100px;
          object-fit: none;
          width: 375px;
        }*/
        @include media-breakpoint-up(sm) {
          height: 100%;
          width: auto;
        }

        @include media-breakpoint-up(md) {
          height: auto;
          width: 100%;
        }
      }

      &__inner {
        height: 100%;
        position: relative;
        text-align: center;
        transform-style: preserve-3d;
        transition: transform 0.8s;
        width: 100%;
      }

      &__back {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        height: 100%;
        position: absolute;
        width: 100%;
      }

      &__back {
        text-align: left;
        transform: rotateY(180deg);

        .bp-card__content {
          padding: 24px;
          position: relative;
          z-index: 1;

          @include media-breakpoint-up(lg) {
            padding: 32px;
          }
        }

        &:before {
          background-color: var(--FF8);

          opacity: 0.9;
          bottom: 0;
          content: "";
          left: -16px;
          position: absolute;
          right: 0;
          top: 0;
          transform: rotate(-6deg);
          z-index: 1;
        }
      }

      &__wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      &__title {
        color: #fff;
        font-family: $font-family-alt;
        font-size: 28px;
        line-height: normal;
        margin: 0;
        text-transform: uppercase;

        @include media-breakpoint-up(xl) {
          font-size: 32px;
        }
      }

      &__description {
        font-size: 16px;
        color: #fff;

        p {
          margin: 0;

          &:not(:last-child) {
            margin: 0 0 16px 0;
          }
        }
      }

      &__button {
        align-items: center;
        display: flex;
        justify-content: center;

        @include media-breakpoint-between(md, xl) {
          grid-column-start: 1;
          grid-column-end: 3;
        }

        .btn-xl {
          @include media-breakpoint-up(lg) {
            font-size: 32px;
          }
        }
      }

      &__container {
        @include img-container;
      }

      &__centered {
        @include centered;
        white-space: nowrap;
        color: white;
      }
    }
  }
}
