@import "swiper/scss";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  // background: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  // height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 30%;
}

.swiper-button-next {
  right: 0;
  visibility: hidden;

  @include media-breakpoint-up(md) {
    visibility: visible;
  }
}

.swiper-button-prev {
  left: 0;
  visibility: hidden;

  @include media-breakpoint-up(md) {
    visibility: visible;
  }
}

.swiper-button-next,
.swiper-button-prev {
  height: 100%;
  margin-top: unset;
  top: unset;
  width: 80px;
  color: #fff !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-family: "Font Awesome 6 Free";
  line-height: 1;
  font-style: normal;
  font-weight: 900;
  opacity: 0.75;
}

.swiper-button-next::after,
.swiper-rtl .swiper-button-prev::after {
  content: "\f138";
}

.swiper-button-prev::after,
.swiper-rtl .swiper-button-next::after {
  content: "\f137";
}

.swiper-button-next:focus::after,
.swiper-button-next:hover::after,
.swiper-button-prev:focus::after,
.swiper-button-prev:hover::after {
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #fff;
}

/*
.swiper-slide:nth-child(2n) {
  width: 60%;
}

.swiper-slide:nth-child(3n) {
  width: 40%;
}
*/