﻿.bp-somos {
  &--main {
    display: flex;
    font-family: $font-family-alt;
    justify-content: center;
    margin: 0 0 0px 0;
  }

  &--title {
    font-size: 32px;
    line-height: normal;
    margin: 0;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      margin: 0px;
      font-size: 40px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 64px;
    }
  }
}

.bp-somos--home {
  //  padding: 48px 0px 0px 0px;

  @include media-breakpoint-up(md) {
    padding: 96px 24px 0px 24px;
    background: url("~/src/img/biciparc-nuestros-valores2.png") no-repeat center;
    background-size: cover;
    height: 540px;
  }

  @include media-breakpoint-up(lg) {
    height: 699px;
    padding: 0 32px 32px 32px;
  }

  @include media-breakpoint-up(xl) {
    height: 845px;
    padding: 0 64px 32px 64px;
  }

  @include media-breakpoint-up(xxl) {
    height: 980px;
    padding: 0 64px 208px 64px;
  }

  .row {
    height: 100%;
  }

  .bp-somos {
    &__background {
      background: url("~/src/img/biciparc-nuestros-valores2.png") no-repeat center;
      background-size: cover;
      height: 500px;
    }

    /* &__title {
      font-family: "Berlin Sans FB Demi", Arial, sans-serif;
      font-size: 18px;
      line-height: normal;
      //   margin: 0 0 8px 0;
      text-transform: uppercase;
    }*/

    &__box {
      /*  background-color: #fff;*/
      padding: 16px;

      @include media-breakpoint-up(md) {
        background-color: transparent;
        padding: 0;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 150px;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 250px;
      }
    }

    &__bottom {

      @include media-breakpoint-up(md) {
        background-color: transparent;
      }
    }

    &__title {
      // font-family: $font-family-alt;
      font-size: 32px;
      line-height: normal;
      margin: 0;
      text-transform: uppercase;
      color: var(--CF3);

      @include media-breakpoint-up(lg) {
        //  font-size: 20px;
      }

      @include media-breakpoint-up(xxl) {
        //  font-size: 22px;
      }

      @include media-breakpoint-up(xxl) {
        //  font-size: 26px;
      }
    }

    &__subtitle {
      //  font-family: $font-family-alt;
      font-size: 20px;
      line-height: normal;
      // margin: 0 0 8px 0;
      //text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
        //  margin: 0 0 16px 0;
      }

      @include media-breakpoint-up(xl) {
        font-size: 28px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 28px;
      }
    }

    &__button {
      margin: 16px 0 0 0;
      text-align: center;

      @include media-breakpoint-up(sm) {
        text-align: left;
      }

      @include media-breakpoint-up(lg) {
        margin: 32px 0 0 0;
      }

      .btn {
        @include media-breakpoint-down(lg) {
          font-size: 16px;
          padding: 4px 16px;
        }
      }
    }
  }
}

.bp-somos--margin {
  height: 98px;
}
