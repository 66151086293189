﻿.bp-opinions--default {
  margin: -64px 0 0 0;

  @include media-breakpoint-up(md) {
    margin: -88px 0 0 0;
  }

  div[class*='col-']:not(:last-child) {
    margin: 0 0 40px 0;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

  .bp-opinions {
    &__item {
      text-align: center;
    }

    &__image {
      margin: 0 0 16px 0;

      @include media-breakpoint-up(lg) {
        margin: 0 0 32px 0;
      }

      img {
        height: 130px;
        width: 130px;
      }
    }

    &__title {
      color: var(--CF3);
      font-family: $font-family-alt;
      font-size: 32px;
      line-height: normal;
      margin: 0;

      @include media-breakpoint-up(xl) {
        font-size: 56px;
      }
    }

    &__description {
      font-size: 20px;
      line-height: 30px;
      margin: 0;

      @include media-breakpoint-up(xl) {
        font-size: 28px;
        line-height: 34px;
        margin: 0 0 32px;
      }
    }

    &__name {
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      margin: 0;

      @include media-breakpoint-up(xl) {
        font-size: 28px;
      }
    }
  }
}
