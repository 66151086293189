﻿.bp-banner--duration {

  background: var(--bp-fileseccion2) no-repeat center;
  background-size: cover;
  height: 480px;
  position: relative;

  @include media-breakpoint-up(lg) {
    height: 790px;
  }

  .bp-banner {
    &__header {
      color: var(--CF7);
      font-family: $font-family-alt;
      left: 32px;
      max-width: 280px;
      position: absolute;
      text-transform: uppercase;
      top: 144px;

      @include media-breakpoint-up(sm) {
        left: 80px;
      }

      &:after {
        background-color: var(--FF3);
        opacity: 0.9;
        clip-path: polygon(16% 0%, 100% 48%, 88% 100%, 0% 80%);
        content: "";
        height: 450px;
        left: -112px;
        position: absolute;
        top: -132px;
        width: 504px;

        @include media-breakpoint-up(sm) {
          top: -176px;
        }
      }
    }

    &__title,
    &__description {
      line-height: normal;
      position: relative;
      z-index: 1;
    }

    &__title {
      font-size: 30px;
      margin: 0 0 8px 0;

      @include media-breakpoint-up(md) {
        font-size: 38px;
      }
    }

    &__description {
      font-size: 18px;
      margin: 0;

      @include media-breakpoint-up(md) {
        font-size: 24px;
      }
    }

    &__buttons {
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 264px;

      @include media-breakpoint-up(sm) {
        left: initial;
        right: 80px;
        top: 280px;
      }

      @include media-breakpoint-up(md) {
        top: 156px;
      }
    }

    &__button {
      &:not(:last-child) {
        margin: 0 0 16px 0;
      }
    }
  }
}

.bp-banner--parking {
  // background: url("~/src/img/biciparc-parking.jpg") no-repeat center;
  background: var(--bp-fileaparcabicis) no-repeat center;
  background-size: cover;
  //height: 580px;
  position: relative;



  @include media-breakpoint-up(lg) {
    height: 816px;
  }

  @include media-breakpoint-between(md, lg) {
    height: 1266px;
  }

  @include media-breakpoint-down(md) {
    height: 1312px;

  }

  @include media-breakpoint-up(md) {
    // height: 816px;
  }






  .bp-banner {
    &__content {
      left: 0;
      position: absolute;
      right: 0;
      top: 164px;
      z-index: 0;



      @include media-breakpoint-up(sm) {
        left: initial;
        right: 80px;
        top: 144px;
      }
    }

    &__contentleft {
      right: 0;
      left: 50%;
      transform: translate(-50%, 0%);
      position: absolute;
      top: 585px;
      //  top: 13px;
      z-index: 0;
      background-color: var(--FF4);
      opacity: 0.9;
      width: 80%;
      max-width: 600px;
      padding: 50px;


      @include media-breakpoint-up(xl) {
        top: 17px;
        left: 80px;
        max-width: 600px;
        transform: unset;
        ;
      }

      @include media-breakpoint-between(lg, xl) {
        top: 16px;
        left: 14px;
        max-width: 518px;
        transform: unset;
      }

      @include media-breakpoint-between(md, lg) {
        top: 533px;
        //   left: 80px;
        left: 50%;
        transform: translate(-50%, 0%);
        max-width: 465px;
      }

      @include media-breakpoint-between(sm, md) {
        top: 533px;
        left: 50%;
        transform: translate(-50%, 0%);
        max-width: 600px;
        right: initial;
      }

      @include media-breakpoint-up(sm) {
        // left: 14px;






      }
    }


    &__header {
      color: #000;
      font-family: $font-family-alt;
      max-width: 280px;
      padding: 0 0 0 32px;
      position: relative;
      text-transform: uppercase;

      @include media-breakpoint-up(sm) {
        padding: 0;
      }

      &:after {
        background-color: var(--FF4);
        opacity: 0.9;
        clip-path: polygon(16% 0%, 100% 48%, 88% 100%, 0% 80%);
        content: "";
        height: 450px;
        left: -112px;
        position: absolute;
        top: -106px;
        width: 504px;
        z-index: -1;
      }
    }

    &__title,
    &__description {
      line-height: normal;
      position: relative;
      z-index: 1;
    }

    &__title {
      font-size: 30px;
      margin: 0 0 8px 0;
      color: var(--CF1);

      @include media-breakpoint-up(md) {
        font-size: 38px;
      }
    }

    &__description {
      font-size: 18px;
      margin: 0;
      color: var(--CF1);

      @include media-breakpoint-up(md) {
        font-size: 24px;
      }
    }

    &__buttons {
      margin: 28px 0 0 0;
      text-align: center;
    }

    &__button {
      &:not(:last-child) {
        margin: 0 0 16px 0;
      }
    }
  }
}

.bp-banner--values {
  background: url("~/src/img/biciparc-nuestros-valores2.png") no-repeat center;
  background-size: cover;
  /*margin: 48px 0 0 0;*/
  padding-top: 420px;
  height: 580px;
  position: relative;

  @include media-breakpoint-up(md) {
    height: 990px;
    padding-top: 420px;
  }
}
