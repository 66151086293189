﻿html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

markdown img {
  width: 100%;
}