.radio {
  margin: 16px 0;
  display: block;
  cursor: pointer;
}

.radio input {
  display: none;
}

.radio input+span {
  line-height: 22px;
  height: 22px;
  padding-left: 22px;
  display: block;
  position: relative;
}

.radio input+span:not(:empty) {
  padding-left: 30px;
}

.radio input+span:before,
.radio input+span:after {
  content: "";
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 50%;
  left: 0;
  top: 0;
  position: absolute;
}

.radio input+span:before {
  background: #d1d7e3;
  -webkit-transition: background 0.2s ease,
    -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
  transition: background 0.2s ease,
    -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
  transition: background 0.2s ease,
    transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
  transition: background 0.2s ease,
    transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2),
    -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
}

.radio input+span:after {
  background: #fff;
  -webkit-transform: scale(0.78);
  transform: scale(0.78);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
  transition: -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4),
    -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
}

.radio input:checked+span:before {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
  background: #5d9bfb;
}

.radio input:checked+span:after {
  -webkit-transform: scale(0.4);
  transform: scale(0.4);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.radio:hover input+span:before {
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
}

.radio:hover input+span:after {
  -webkit-transform: scale(0.74);
  transform: scale(0.74);
}

.radio:hover input:checked+span:after {
  -webkit-transform: scale(0.4);
  transform: scale(0.4);
}

/* Importing Bootstrap SCSS file. */

//@import "../node_modules/primeicons/primeicons.css";
//@import "../node_modules/primeng/resources/themes/lara-light-indigo/theme.css";

//@import "../node_modules/primeng/resources/primeng.min.css";

@import "SCSS/index.scss";