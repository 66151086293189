﻿.bp-nav--main {
  padding: 20px 0;

  .minimenu {
    //  background-color: rgba(#fff, 0.8);
    border: 0;
    //padding: 8px;

    @include media-breakpoint-up(xl) {
      /* padding: 32px 2px 2px 1000px;*/
    }

    @media all and (min-width: 992px) {
      //     left: 0;
      //     margin: 0;
      //     right: 0;
      //     width: 100%;
      //     padding-top: 10px;
    }

    &__list {
      li {
        cursor: pointer;
        font-size: 20px;
        /*text-transform: uppercase;*/
        transition: all ease 0.2s;

        &:hover {
          color: var(--CF3);
        }

        a {
          color: var(--CF1);

          &:hover {
            color: var(--CF3);
          }
        }
      }
    }

    &__centered {
      @include centered;
    }

    &__container {
      @include img-container;
    }
  }

  .megamenu {
    background-color: rgba(#fff, 0.8);
    border: 0;
    padding: 8px;

    @include media-breakpoint-up(xl) {
      padding: 32px 32px 32px 345px;
    }

    @media all and (min-width: 992px) {
      left: 0;
      margin: 0;
      right: 0;
      width: 100%;
    }

    &__list {
      li {
        cursor: pointer;
        font-size: 20px;
        text-transform: uppercase;
        transition: all ease 0.2s;

        &:hover {
          color: var(--CF3);
        }

        a {
          //color: $dark;
          color: var(--CF1);

          &:hover {
            color: var(--CF3);
          }
        }
      }
    }

    &__images {
      position: relative;

      li {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.2s ease;
        visibility: hidden;

        &.active {
          opacity: 1;
          visibility: visible;
        }
      }

      img {
        max-height: 208px;
      }
    }

    &__centered {
      @include centered;
    }

    &__container {
      @include img-container;
    }
  }

  .has-megamenu {
    @media all and (min-width: 992px) {
      position: static !important;
    }
  }

  .has-minimenu {
    @media all and (min-width: 992px) {
      position: static !important;
    }
  }

  &.fixed-top .navbar-collapse,
  &.sticky-top .navbar-collapse {
    @media (max-width: 991px) {
      margin: 10px 0 0 0;
      max-height: 90vh;
      overflow-y: auto;
    }
  }

  .navbar-brand {
    @include media-breakpoint-down(sm) {
      margin: 0;
    }
  }

  .nav-item {
    color: var(--CF1);
    font-family: $font-family-alt;
    font-size: 20px;

    &:not(:last-child) {
      @include media-breakpoint-up(xl) {
        margin-right: 16px;
        padding-right: 16px;
      }

      .nav-link {
        @include media-breakpoint-up(xl) {
          position: relative;
        }

        &:before {
          @include media-breakpoint-up(xl) {
            background-color: #000;
            content: "";
            height: 32px;
            position: absolute;
            right: -18px;
            top: 8px;
            width: 2px;
          }
        }
      }
    }

    .nav-link {
      color: var(--CF1);
    }
  }
}

.bp-nav--footer {
  .bp-nav {
    &__title {
      font-family: $font-family-alt;
      //   font-size: 28px;
      line-height: normal;
      margin: 0 0 24px 0;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        //   font-size: 32px;
        margin: 0 0 32px 0;
      }
    }

    &__list {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        font-family: $font-family-alt;
        //font-size: 20px;
        line-height: normal;

        @include media-breakpoint-up(md) {
          //   font-size: 26px;
        }

        &:not(:last-child) {
          margin: 0 0 8px 0;
        }
      }

      a {
        color: var(--CF2);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__social {
      display: flex;
      list-style-type: none;
      margin: 0 0 16px 0;
      padding: 0;

      li {
        &:not(:last-child) {
          margin: 0 12px 0 0;
        }
      }

      svg {
        height: 40px;
        width: 40px;

        path {

          fill: var(--CF2);
        }
      }
    }

    &__label {
      font-family: $font-family-alt;
      //   font-size: 26px;
      line-height: normal;
    }

    &__newsletter {
      margin: 0 0 16px 0;

      input {
        border: 0;
        height: 32px;
        padding: 0 8px;
        width: 100%;
      }
    }
  }
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.dropdown-menu .row>.col,
.dropdown-menu .row>[class^="col-"] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

}
