﻿.bp-hero--home {
  //background: url("~/src/img/hero-bicycle-mobile.jpg") no-repeat center;
  background: var(--bp-filefondomobile) no-repeat center;
  background-size: cover;
  overflow: hidden;
  padding: 124px 24px 64px 32px;

  @include media-breakpoint-up(md) {
    padding: 96px 24px 24px 24px;
  }

  @include media-breakpoint-up(lg) {
    /*background: url("~/src/img/hero-bicycle.jpg") no-repeat center;*/
    background: var(--bp-filefondodesktop) no-repeat center;
    height: 525px;
    padding: 0 32px 32px 32px;
  }

  @include media-breakpoint-up(xl) {
    height: 625px;
    padding: 0 64px 32px 64px;
  }

  @include media-breakpoint-up(xxl) {
    height: 980px;
    padding: 0 64px 208px 64px;
  }

  .row {
    height: 100%;
    position: relative;
  }

  .bp-hero {
    &__content {
      color: var(--CF1);
      height: 100%;
      position: relative;

      &:before {
        background: url("~/src/img/bg-hero-home.png") no-repeat top left;
        background-size: contain;
        content: "";
        height: 110%;
        left: -32px;
        position: absolute;
        top: -36px;
        width: 360px;

        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    &__title {
      font-family: $font-family-alt;
      font-size: 16px;
      line-height: normal;
      margin: 0;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        font-size: 32px;
      }
    }

    &__subtitle {
      font-family: $font-family-alt;
      font-size: 24px;
      line-height: normal;
      margin: 0 0 4px 0;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        font-size: 32px;
        margin: 0 0 16px 0;
      }

      @include media-breakpoint-up(xl) {
        font-size: 40px;
      }
    }

    &__button {
      margin: 8px 0 0 0;

      @include media-breakpoint-up(lg) {
        margin: 32px 0 0 0;
      }

      .btn {
        @include media-breakpoint-down(lg) {
          font-size: 16px;
          padding: 4px 16px;
        }
      }
    }
  }
}

.bp-hero--margin {
  height: 98px;
}
