.login-box,
.register-box {
  max-width: 360px;
  margin: 7% auto;
}

.login-logo,
.register-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
}

.login-logo a,
.register-logo a {
  color: #444;
}

.login-box-body,
.register-box-body {
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;
}

.login-box-msg,
.register-box-msg {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}

.icheck>label {
  padding-left: 0;
}

.btn-small {
  line-height: 0.8;
}

.nombre_parking {
  margin: 1rem 0;
  padding-right: 3.5rem;
  display: inline-block;
}

/*
.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item label {
  color: #666;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #ffc533;
  background-color: transparent;
}

.radio-item input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #ffc533;
}
*/
.redcolor {
  color: #f00;
}

.pulsate {
  -webkit-animation: pulsate 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.5;
  color: #ffc533;
}

.pulsatefast {
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.5;
  color: #f00;
}

@keyframes pulsate {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}
