﻿$font-family-base: "Myriad Pro", Arial, sans-serif;
$font-family-alt: "Berlin Sans FB Demi", Arial, sans-serif;



$accordion-button-active-bg: var(--FF9);
$accordion-button-active-color: white;
$accordion-button-focus-box-shadow: 0 0 0 0.25rem var(--FF9);


@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/utilities/api";

a:hover {
  cursor: pointer;
}

$utilities: map-merge($utilities,
    ("width": (property: width,
        responsive: true,
        class: wr,
        values: (10: 10%,
          20: 20%,
          25: 25%,
          30: 30%,
          40: 40%,
          50: 50%,
          60: 60%,
          70: 70%,
          75: 75%,
          80: 80%,
          90: 90%,
          100: 100%,
          auto: auto,
        ),
      ),
    ));
