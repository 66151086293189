.lightbox-spinner {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: lightbox-load 1.5s infinite ease-in-out;
  animation: lightbox-load 1.5s infinite ease-in-out;

  &:before,
  &:after {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: lightbox-load 1.5s infinite ease-in-out;
    animation: lightbox-load 1.5s infinite ease-in-out;
    content: "";
    position: absolute;
    top: 0;
  }

  color: #fff;
  font-size: 10px;
  margin: 0 auto;
  margin-bottom: 20px;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &:before {
    left: -32px;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &:after {
    left: 32px;
  }
}

@-webkit-keyframes lightbox-load {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes lightbox-load {
  0%,
  80%,
  100% {
    box-shadow: 0 10px 0 -1.3em;
  }
  40% {
    box-shadow: 0 10px 0 0;
  }
}
