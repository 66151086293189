.marker {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.marker-box {
  margin-bottom: 0.625rem;
  position: relative;
  background-color: #fff;
  border: 2px solid #ffc533;
  border-radius: 6px;
}
.marker-box:after {
  content: "";
  z-index: 0;
  display: block;
  margin: 0 auto;
  position: absolute;
  bottom: -0.75rem;
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.625rem 0.625rem 0;
  border-color: #ffc533 transparent transparent;
}
.marker-box:before {
  content: "";
  z-index: 1;
  display: block;
  margin: 0 auto;
  position: absolute;
  bottom: -0.5625rem;
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.5625rem 0.5625rem 0;
  border-color: #fff transparent transparent;
}
.marker-box .content-box,
.marker-box .price-box {
  cursor: pointer;
  display: block;
  padding: 0.3125rem 0.5rem;
  font-family: "Museo Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  font-size: 0.9375rem;
  color: #ffc533;
}
.marker-box .content-box sup,
.marker-box .price-box sup {
  top: -0.21875rem;
}
