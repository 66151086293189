﻿.bp-footer {
  color: var(--CF2);

  &__top {
    background-color: var(--FF5);
    padding: 40px 32px 16px 32px;

    @include media-breakpoint-up(lg) {
      padding: 40px 56px;
    }
  }

  &__bottom {
    background-color: #000;
    padding: 24px 40px;

    @include media-breakpoint-up(lg) {
      padding: 24px 40px;
    }

    .bp-footer__grid {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }
  }

  &__col {
    border-bottom: 3px solid #fff;
    margin: 0 0 24px 0;
    padding: 0 0 24px 0;

    @include media-breakpoint-up(lg) {
      border-bottom: 0;
      border-left: 3px solid #fff;
      margin: 0;
      padding: 0 32px;
    }
  }

  &__copy {
    font-family: $font-family-alt;
    //font-size: 18px;
    line-height: normal;
    margin: 0 0 16px 0;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

  &__logo {
    img {
      height: 60px;
    }
  }
}

/*
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 673px;

  margin-right: auto;
  margin-left: auto;
}
*/
/* Footer height */
$Footerheight: 27rem;

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: $Footerheight;
  /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $Footerheight;
  /* Footer height */
}
