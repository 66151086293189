.bocadillo-cuadrado {
  position: relative;
  height: 200px;
  width: 300px;
  background: white;
  box-shadow: 1px 12px 33px rgba(0, 0, 0, 0.5);
}

.bocadillo-cuadrado:before {
  border: 25px solid white;
  content: "";
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  position: absolute;
  bottom: -48px;
  left: calc(50% - 25px);
}

.bocadillo-redondo {
  position: relative;
  height: 50px;
  width: 100%;
  background: var(--FF10); //  $secondary $ bp-green;
  border-radius: 0% 0% 100% 100%;
  box-shadow: 1px 12px 33px rgba(0, 0, 0, 0.5);
}

.bocadillo-redondo:before {
  border: 25px solid var(--FF10);
  content: "";
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  position: absolute;
  bottom: -44px;
  right: 50%;
  transform: rotate(-8deg);
}

.bocadillo-redondo-invertido {
  position: relative;
  height: 50px;
  width: 100%;
  background: var(--FF10);
  border-radius: 100% 100% 0% 0%;
  box-shadow: 1px -2px 33px -5px rgba(0, 0, 0, 0.5);
}

.bocadillo-redondo-invertido:before {
  border: 25px solid var(--FF10);
  content: "";
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  position: absolute;
  top: -41px;
  transform: rotate(172deg);
}

.nombrebocadillo {
  position: absolute;
  bottom: 0.5rem;
  left: 22%;
}

.nombrebocadillo-invertido {
  bottom: 0.5rem;
}
