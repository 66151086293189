﻿.btn {
  font-size: 20px;
  font-family: $font-family-alt;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border: 1px solid transparent;

  &:hover,
  &:focus {
    color: #fff;

  }

  &-primary {
    border-radius: 24px;
    padding: 8px 24px;
    color: var(--CF2);
    background-color: var(--FF1);
    --bs-btn-active-bg: var(--FF1);
    --bs-btn-active-border-color: var(--FF1);

    &:disabled,
    &.disabled {
      color: var(--CF2);
      background-color: color-mix(in srgb, white 15%, var(--FF1));
      border-color: color-mix(in srgb, white 20%, var(--FF1));
    }

    &:hover,
    &:focus {
      color: var(--CF2);
      background-color: color-mix(in srgb, white 15%, var(--FF1));
      border-color: color-mix(in srgb, white 20%, var(--FF1));
    }
  }

  &-info {
    border-radius: 24px;
    padding: 8px 24px;
    color: #fff;
    background-color: var(--FF2);
    --bs-btn-active-bg: var(--FF2);
    --bs-btn-active-border-color: var(--FF2);
    --bs-btn-active-color: #fff;

    &:hover,
    &:focus,
    &:active {
      background-color: color-mix(in srgb, white 15%, var(--FF2));
      border-color: color-mix(in srgb, white 20%, var(--FF2));
    }

  }

  &-secondary {
    border-radius: 0;
    clip-path: polygon(90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%, 10% 0);
    background-color: var(--FF2);
    --bs-btn-active-bg: var(--FF2);
    --bs-btn-active-border-color: var(--FF2);
    color: var(--CF5);

    &:hover,
    &:focus {
      color: var(--CF5);
      background-color: color-mix(in srgb, white 15%, var(--FF2));
      border-color: color-mix(in srgb, white 20%, var(--FF2));
    }
  }

  &-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  &-lg {
    border-radius: 32px;
    font-size: 24px;
    padding: 8px 24px;

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
  }

  &-xl {
    border-radius: 40px;
    font-size: 24px;
    padding: 8px 24px;

    @include media-breakpoint-up(lg) {
      font-size: 40px;
      padding: 8px 32px;
    }
  }

  &-with-shadow {
    position: relative;

    &:before {
      background-color: var(--FF6);
      clip-path: polygon(90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%, 10% 0);
      content: "";
      height: 100%;
      left: 32px;
      position: absolute;
      top: 24px;
      width: 100%;
      z-index: -1;
    }
  }
}

.classic_button_next {
  position: absolute;
  right: 5px;
  top: 5px;
}

.tmb {
  max-width: 100%;
  object-fit: contain;
}

.input-group {
  .btn-middle {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .btn-end {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
}

.bg-color-sucess {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
